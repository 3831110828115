import $ from 'jquery';
import {debounce} from "lodash";
import {preventEnterSubmittingForm, tableFilter} from "../shared/common";

$(function () {
    $(`input#keyword_visibility_filter-evt_typ`)
        .on("keypress", (e) => preventEnterSubmittingForm(e))
        .on("keyup", debounce((e) => {
            tableFilter(e.currentTarget.value, {
                table: $(`#event-types-container table tbody`),
                filter_by_inputs: true,
                excluded_row_classes: ".template .fadeOut"
            })
        }, 300));
});